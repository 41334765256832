export default class LastRideStatus {
  status() {
    return {
      name: 'Last Ride',
      key: [0, 0],
      params: 'last_ride',
      is_active: false,
    }
  }
}
