import { TripConfig } from '@/config/TripConfig'
import FilterService from './FilterServices'

export default class TripFilter extends FilterService {
  constructor(http) {
    super(http)
    this.sortUrl = TripConfig.api.index + '?'
    this.apiUrl = TripConfig.api.index
  }
  async getFilterOptions() {
    let fleets = await this.getAllfleets()
    let tripstatus = this.lastTripStatus()
    let tripcosts = this.tripCosts()
    let last_ride = this.lastRide()
    let trip_duration = this.tripDuration()
    return { fleets, tripstatus, tripcosts, last_ride, trip_duration }
  }
  async paginate() {
    let query = `${this.sortUrl}&page=${this.currentPage}&limit=${this.postLimit}&offset=${this.offset}`
    let { data, meta } = await this.http.get(query).then((data) => data.data)
    return { data, meta }
  }
  async search(val) {
    if (!val) {
      return
    } else {
      this.sortUrl = TripConfig.api.index + '?'
      this.apiUrl = TripConfig.api.index
      let query = `${this.sortUrl}search=${val}`
      let { data, meta } = await this.http.get(query).then((data) => data.data)
      this.sortUrl = query
      return { data, meta }
    }
  }
  async queryBuilder(params) {
    if (!params) {
      let { data, meta } = await this.http
        .get(this.apiUrl)
        .then((data) => data.data)
      return { data, meta }
    } else {
      if (params.search) {
        let { data, meta } = await this.search(params.search)
        return { data, meta }
      }
      if (params.pagination) {
        let { updatedPage } = params.pagination.detail
        this.currentPage = updatedPage
        this.offset = (this.currentPage - 1) * this.postLimit
        let { data, meta } = await this.paginate()
        return { data, meta }
      }

      if (params.limitUpdate) {
        let { updatedLimit } = params.limitUpdate.detail
        this.postLimit = updatedLimit
        let { data, meta } = await this.paginate()
        console.log({ data, meta })
        return { data, meta }
      }

      let query = ''
      let tripcostsquery = ''
      let last_ride_query = ''
      let trip_duration_query = ''
      let fleets = ''
      let tripstatus = ''

      if (params.applydate) {
        // Date Hook
        let { start, end } = params.applydate
        this.startDate = start
        this.endDate = end
        // reset the page to 1
        // looks like it's not properly handled
        this.currentPage = 1
        this.offset = (this.currentPage - 1) * this.postLimit
      }
      /* Filter using user Choice */

      // trip costs
      if (params.tripcosts) {
        let { tripcosts } = params
        if (tripcosts[1] < 1) {
          tripcostsquery = ''
        } else {
          tripcostsquery = `&min_cost=${tripcosts[0]}&max_cost=${tripcosts[1]}`
        }
      }

      // last ride
      if (params.last_ride) {
        let { last_ride } = params
        if (last_ride[1] < 1) {
          last_ride_query = ''
        } else {
          last_ride_query = `&last_ride_from_days=${last_ride[0]}&last_ride_to_days=${last_ride[1]}`
        }
      }
      // trip duration
      if (params.trip_duration) {
        let { trip_duration } = params
        if (trip_duration[1] < 1) {
          trip_duration_query = ''
        } else {
          trip_duration_query = `&min_duration_mins=${trip_duration[0]}&max_duration_mins=${trip_duration[1]}`
        }
      }
      // fleets
      if (params.fleets) {
        let selectedFleets = params.fleets
        fleets = ''
        selectedFleets.forEach((element) => {
          fleets += `&fleet=${element.id}`
        })
      }
      // trip status
      if (params.tripstatus) {
        let selectedTripStatus = params.tripstatus
        selectedTripStatus.forEach((element) => {
          tripstatus += `&status=${element.key}`
        })
      }
      // build query using user choice
      query =
        (this.startDate ? `&start_date=${this.startDate}` : '') +
        (this.endDate ? `&end_date=${this.endDate}` : '') +
        (fleets ? fleets : '') +
        (tripstatus ? tripstatus : '') +
        (tripcostsquery ? tripcostsquery : '') +
        (last_ride_query ? last_ride_query : '') +
        (last_ride_query ? last_ride_query : '') +
        (trip_duration_query ? trip_duration_query : '')
      let sorturl = (this.sortUrl += query)

      let { data, meta } = await this.http
        .get(sorturl)
        .then((data) => data.data)
      return { data, meta }
    }
  }

  async getTripData(params) {
    if (params) {
      let { data, meta } = await this.queryBuilder(params)
      return { data, meta }
    } else {
      let { data, meta } = await this.queryBuilder()
      return { data, meta }
    }
  }
}
