export default class {
  accountType = [
    {
      name: 'On Hold',
      key: 'H',
      is_checked: false,
      params: 'accounttype',
    },
    {
      name: 'Verified',
      key: 'A',
      is_checked: false,
      params: 'accounttype',
    },
    {
      name: 'Unverified',
      key: 'D',
      is_checked: false,
      params: 'accounttype',
    },
  ]
  getAccountType() {
    return {
      name: 'Account Type',
      options: this.accountType,
      params: 'account_type',
      is_active: false,
    }
  }
}
