export default class TripCosts {
  getTripCosts() {
    return {
      name: 'Trip Costs',
      key: [0, 0],
      params: 'tripcosts',
      is_active: false,
    }
  }
}
