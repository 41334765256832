export default class TripDuration {
  getTripDuration() {
    return {
      name: 'Trip Duration',
      key: [0, 0],
      params: 'trip_duration',
      is_active: false,
    }
  }
}
