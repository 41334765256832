import { FleetConfig } from '@/config/FleetConfig'
export default class Fleets {
  constructor(http) {
    this.http = http
  }
  async getAllFleets() {
    let api = FleetConfig.api.index
    let fleets = await this.http.get(api).then((data) => data.data.data)
    let options = []
    let fleetList = ''
    fleets.forEach((item) => {
      options.push({
        name: item.name,
        id: item.id,
        is_checked: false,
      })
    })
    fleetList = {
      name: 'Fleets',
      options: options,
      params: 'fleets',
      is_active: false,
    }
    return fleetList
  }
}
