export default class CurrentBalance {
  getCurrentBalance() {
    return {
      name: 'Current Balance',
      key: [0, 0],
      params: 'balance',
      is_active: false,
    }
  }
}
