import { mapGetters } from 'vuex'
export const tripData = {
  data: () => ({
    filterOption: '',
    activeFilterCount: 0,
    appliedFilters: [],
    loaded: false,
    activeGroupFilter: '',
    filterable: {},
    tableData: '',
    tableMeta: '',
    pageOffset: 0,
    dataLimit: 10,
    tripSearch: '',
  }),
  computed: {
    ...mapGetters({
      filter: 'tripIndex/getFilterOption',
      filtered: 'tripIndex/getFilterable',
      getpaginationIndex: 'tripIndex/getPaginationIndex',
      table_data: 'tripIndex/getTableData',
      table_meta: 'tripIndex/getTableMeta',
      cancelled: 'tripIndex/getCancelled',
      completed: 'tripIndex/getCompleted',
      on_trip: 'tripIndex/getOnTrip',
      total: 'tripIndex/getTotal',
    }),
    currency() {
      return this.organizationInfo.country.currency_symbol || '$'
    },
    tableDatas() {
      return this.tableData
    },
    fleetFilterSummary() {
      let summary = ''
      let fleetOptions = this.filterOption
      let fleetFilterOptions = ''
      if (fleetOptions) fleetFilterOptions = this.filterOption.fleets.options
      if (fleetFilterOptions) {
        for (let optionKey in fleetFilterOptions) {
          if (fleetFilterOptions[optionKey]['is_checked']) {
            if (summary === '') {
              summary = fleetFilterOptions[optionKey]['name']
            } else {
              summary = 'Fleet'
              break
            }
          }
        }
      }

      return summary === '' ? 'Fleet' : summary
    },
    lastTripSummery() {
      let summary = ''
      let tripstatus = this.filterOption['tripstatus']['options']
      for (let key in tripstatus) {
        if (tripstatus[key]['is_checked']) {
          if (summary === '') {
            summary = tripstatus[key]['name']
          } else {
            summary = 'Status'
            break
          }
        }
      }
      return summary === '' ? 'Status' : summary
    },
    tripCostsSummery() {
      let tripsummery = this.filterOption['tripcosts']['key']
      return typeof tripsummery === 'undefined' ||
        (tripsummery[0] === 0 && tripsummery[1] === 0)
        ? ''
        : this.currency +
            ' ' +
            tripsummery[0] +
            ' - ' +
            (this.currency + ' ' + tripsummery[1])
    },
    lastTripFilterOptionSummery() {
      let lastRideSummery = this.filterOption['last_ride']['key']
      return typeof lastRideSummery === 'undefined' ||
        (lastRideSummery[0] === 0 && lastRideSummery[1] === 0)
        ? ''
        : lastRideSummery[0] + ' - ' + lastRideSummery[1] + 'Day'
    },
    tripDurationOptionSummery() {
      let tripduration = this.filterOption['trip_duration']['key']
      return typeof tripduration === 'undefined' ||
        (tripduration[0] === 0 && tripduration[1] === 0)
        ? ''
        : tripduration[0] + ' - ' + tripduration[1] + 'min'
    },
    filterSummery() {
      return {
        fleets: this.fleetFilterSummary,
        tripstatus: this.lastTripSummery,
        tripcosts: this.tripCostsSummery,
        last_ride: this.lastTripFilterOptionSummery,
        trip_duration: this.tripDurationOptionSummery,
      }
    },
    paginationsSummary() {
      const total = this.total
      let index = this.pageOffset + 1
      let offset = this.pageOffset + this.dataLimit
      if (index > total) {
        index = total
      }
      if (offset > total) {
        offset = total
      }
      return `Showing ${index} - ${offset} of ${total} items`
    },
  },
  methods: {
    async getData(val) {
      this.loaded = false
      let data = await this.dataModel.getTripData(val)
      this.$store.dispatch('tripIndex/tableData', data)
      this.tableData = this.table_data
      this.loaded = true
    },
    filterData() {
      let appliedFilter = []
      for (let filter in this.filterOption) {
        if (Array.isArray(this.filterOption[filter]['options'])) {
          for (let item in this.filterOption[filter]['options']) {
            if (
              this.filterOption[filter]['options'][item]['is_checked'] === true
            ) {
              appliedFilter.push(this.filterOption[filter]['params'])
              this.$set(this.filterOption[filter], 'is_active', true)
              break
            }
          }
        } else if (Array.isArray(this.filterOption[filter]['key'])) {
          if (this.filterOption[filter]['key'][1] > 0) {
            appliedFilter.push(this.filterOption[filter]['params'])
            this.$set(this.filterOption[filter], 'is_active', true)
          } else {
            this.$set(this.filterOption[filter], 'is_active', false)
          }
        } else if (this.filterOption[filter]['key'] === 'true') {
          appliedFilter.push(this.filterOption[filter]['params'])
          this.$set(this.filterOption[filter], 'is_active', true)
        } else {
          this.$set(this.filterOption[filter], 'is_active', false)
        }
      }
      let val = this.filterable
      this.getData(val)
      this.appliedFilters = appliedFilter
      this.$store.dispatch('tripIndex/appliedFilters', appliedFilter)
      this.$store.dispatch('tripIndex/filterable', val)
      this.activeFilterCount = appliedFilter.length
    },
    clearAllFilter() {
      for (let filter in this.filterOption) {
        if (Array.isArray(this.filterOption[filter]['options'])) {
          for (let item in this.filterOption[filter]['options']) {
            if (
              this.filterOption[filter]['options'][item]['is_checked'] === true
            ) {
              this.filterOption[filter]['options'][item]['is_checked'] = false
            }
          }
        } else if (Array.isArray(this.filterOption[filter]['key'])) {
          if (this.filterOption[filter]['key'][1] > 0) {
            this.filterOption[filter]['key'][0] = 0
            this.filterOption[filter]['key'][1] = 0
            this.$set(this.filterOption[filter], 'key'[0], 0)
            this.$set(this.filterOption[filter], 'key'[1], 0)
          }
        } else if (this.filterOption[filter]['key'] === 'true') {
          this.filterOption[filter]['key'] = 'false'
        }
      }
      this.activeFilterCount = 0
      this.appliedFilters = []
      this.$store.dispatch('tripIndex/appliedFilters', '')
      this.$store.dispatch('tripIndex/filterable', {})
      this.getFilterOptions()
      this.getData()
    },
    filterChange(option, key, array = false) {
      if (!array) {
        this.filterable[key] = option
      } else {
        this.filterOption[key]['key'] = option
        this.filterable[key] = option
      }
    },
    async clearSelectedFilter(item) {
      for (let key in this.filterOption) {
        if (this.filterOption[key].params === item) {
          if (Array.isArray(this.filterOption[key]['options'])) {
            for (let item in this.filterOption[key]['options']) {
              if (
                this.filterOption[key]['options'][item]['is_checked'] === true
              ) {
                this.$set(
                  this.filterOption[key]['options'][item],
                  'is_checked',
                  false
                )
              }
            }
          } else if (Array.isArray(this.filterOption[key]['key'])) {
            this.$set(this.filterOption[key]['key'], 1, 0)
            this.$set(this.filterOption[key]['key'], 0, 0)
          } else if (this.filterOption[key]['key'] === 'true') {
            this.$set(this.filterOption[key], 'key', false)
          }
        }
      }
      let filterable = this.filterable
      let newFilter = Object.keys(filterable).reduce((obj, key) => {
        if (key !== item) {
          obj[key] = filterable[key]
        }
        return obj
      }, {})
      this.filterable = newFilter
      this.$store.dispatch('tripIndex/filterable', newFilter)
      this.filterData()
    },
    async getFilterOptions() {
      this.loaded = false
      let filterOption = await this.dataModel.getFilterOptions()
      this.$store.dispatch('tripIndex/filterOption', filterOption)
      this.filterOption = this.filter
      this.loaded = true
    },
    async paginateData() {
      window.addEventListener('current-limit-updated', async (e) => {
        this.dataLimit = e.detail.updatedLimit
        await this.getData({ limitUpdate: e })
      })
      window.addEventListener('current-page-updated', async (e) => {
        await this.getData({ pagination: e })
        let paginationIndex = e.detail.updatedPage || 1
        this.$store.dispatch('tripIndex/paginationIndex', paginationIndex)
      })
    },
  },
  async mounted() {
    if (!this.filter && !this.table_data) {
      await this.getFilterOptions()
      await this.getData()
    } else {
      this.tableData = this.table_data
      this.filterOption = this.filter
    }
    if (this.filtered) {
      this.filterData()
    }
    if (this.getpaginationIndex > 1) {
      let index = this.getpaginationIndex
      let params = {
        detail: {
          updatedPage: index,
        },
      }
      await this.getData({ pagination: params })
    }
    this.loaded = true
    this.paginateData()
  },
  watch: {
    tripSearch: {
      handler(data) {
        setTimeout(async () => {
          this.activeFilterCount = 0
          this.appliedFilters = []
          this.$store.dispatch('tripIndex/appliedFilters', '')
          this.$store.dispatch('tripIndex/filterable', {})
          await this.getData({ search: data })
        }, 800)
      },
    },
  },
}
