import AccountType from './FilterMethod/AccountType'
import CurrentBalance from './FilterMethod/CurrentBalance'
import Fleets from './FilterMethod/Fleets'
import LastRideStatus from './FilterMethod/LastRide'
import TripCosts from './FilterMethod/TripCosts'
import TripsDurations from './FilterMethod/TripDurations'
import TripStatus from './FilterMethod/TripStatus'
/*
 *
 * To instansiate you have to pass axios or data fething methods
 * Accept params {pagination, limitUpdate, sort, applydate, account, trip, balance, card, }
 * function for data getIndexData()
 * function filter for options getFilterOption()
 * @return {mata, meta}
 * WIP [ Needed Refector and more readable]
 */
export default class FilterService {
  sortUrl = ''
  apiUrl = ''
  currentPage = 1
  postLimit = 10
  offset = 0
  meta = ''
  startDate = ''
  endDate = ''
  querString = ''
  /**
   * @date 2021-02-21
   * @param {any} http
   * @returns {any}
   */
  constructor(http) {
    this.http = http
    this.fleet = new Fleets(this.http)
    this.accountType = new AccountType()
    this.tripStatus = new TripStatus()
    this.balance = new CurrentBalance()
    this.ride = new LastRideStatus()
    this.tripsCosts = new TripCosts()
    this.TripsDurations = new TripsDurations()
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} data, meta
   */
  async sortData(params) {
    let query = `&sort=${params.sort}&order=${params.order}`
    this.querString = query
    let dataIndex = this.sortUrl + query
    let { data, meta } = await this.http
      .get(dataIndex)
      .then((data) => data.data)
    return { data, meta }
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} Fleets
   */
  async getAllfleets() {
    return await this.fleet.getAllFleets()
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} Account Type
   */
  AccountType() {
    return this.accountType.getAccountType()
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} Last Trip Status
   */
  lastTripStatus() {
    return this.tripStatus.getTripStatus()
  }

  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} Current Balance
   */
  currentBalance() {
    return this.balance.getCurrentBalance()
  }

  tripCosts() {
    return this.tripsCosts.getTripCosts()
  }

  tripDuration() {
    return this.TripsDurations.getTripDuration()
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} Last Ride
   */
  lastRide() {
    return this.ride.status()
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} Card Added
   */
  cardStatus() {
    return {
      name: 'Card Added',
      key: false,
      params: 'card_added',
      is_active: false,
    }
  }

  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} data, meta
   */
  async paginateData() {
    let query = ''
    if (this.currentPage > 1)
      query = `${this.sortUrl}&page=${this.currentPage}&limit=${this.postLimit}&offset=${this.offset}`
    let { data, meta } = await this.http.get(query).then((data) => data.data)
    return { data, meta }
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} data, meta
   */
  async getDataByFilter(params) {
    let startDate = this.startDate ?? null
    let endDate = this.endDate ?? null
    let DateFilter = startDate
      ? `start_date=${startDate}&end_date=${endDate}`
      : ''
    if (params) {
      if (params.pagination) {
        let { updatedPage } = params.pagination.detail
        this.currentPage = updatedPage
        this.offset = (this.currentPage - 1) * this.postLimit
        let { data, meta } = await this.paginateData()
        return { data, meta }
      }
      /* Check User update view Limit */
      if (params.limitUpdate) {
        let { updatedLimit } = params.limitUpdate.detail
        this.postLimit = updatedLimit
        let { data, meta } = await this.paginateData()
        return { data, meta }
      }
      /* 
        Check IF user sort Data
      */
      if (params.sort) {
        let { data, meta } = await this.sortData(params)
        return { data, meta }
      }
      /* Check if user trying to get data by date */
      if (params.applydate) {
        let { start, end } = params.applydate
        this.startDate = start
        this.endDate = end
        return await this.getDataByFilter()
      }
      /* 
        Query By filter Options
      */
      let accountTypeQuery = ''
      let tripQuery = ''
      let balanceQuery = ''
      let cardQuery = ''
      if (params.account_type) {
        params.account_type.forEach((item) => {
          accountTypeQuery += `&user_status=${item.key}`
        })
      }
      if (params.tripstatus) {
        params.tripstatus.forEach((item) => {
          tripQuery += `&trip_status=${item.key}`
        })
      }
      if (params.balance) {
        if (params.balance[1] > 0) {
          balanceQuery = `&min_balance=${params.balance[0]}&max_balance=${params.balance[1]}`
        }
      }
      if (params.card_added) {
        if (params.card_added === 'true') {
          cardQuery = `&has_card=${params.card}`
        }
      }

      let query = `?${DateFilter ? DateFilter + '&' : ''}${
        accountTypeQuery ? accountTypeQuery : ''
      }${tripQuery ? tripQuery : ''}${balanceQuery ? balanceQuery : ''}${
        cardQuery ? cardQuery : ''
      }`

      this.querString = query
      let filterQuery = this.apiUrl + query
      this.sortUrl = filterQuery
      let { data, meta } = await this.http
        .get(filterQuery)
        .then((data) => data.data)
      return { data, meta }
    } else {
      let filterQuery = this.apiUrl + '?' + DateFilter
      this.sortUrl = filterQuery
      let { data, meta } = await this.http
        .get(filterQuery)
        .then((data) => data.data)
      return { data, meta }
    }
  }
  /**
   * @date 2021-02-21
   * @param {object} params
   * @returns {object} data, meta
   */
  async getIndexData(params) {
    if (!params) {
      return await this.getDataByFilter()
    }
    return this.getDataByFilter(params)
  }
}
